import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Mixtapes, page Artiste" />

      <div className="columns is-centered is-marginless">
        <div className="column is-8">
          <div className="container">
            <iframe
              title="form Mixtapes"
              width="540"
              height="1000"
              src="https://dcbfd423.sibforms.com/serve/MUIEABuTBnS-WlJ-6IEjZKuM2RE-kKlVlp0TlWLSMt9VbJCgAQWUi1hwKgVbBjx_CFykrsSH8yhg0Ia8jk_N8H8di5nD8FnyvUox9bJZ3d4s2-MeIohynp3g4ZLTFyRzAzlMcxuafHaUatpy2GxRQVEYjiVFwrF1ygZYCaNQMtE4NKjJ3CzTznzqq7yvBMHvuWGeRwv6EeZDVI0Y"
              frameBorder="0"
              scrolling="auto"
              allowFullScreen
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
